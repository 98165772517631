<template>
    <v-card  class="mb-2" flat>
        <v-toolbar flat prominent>
            <template v-slot:img="">
                <v-img overla src="https://img.freepik.com/free-photo/workspace-workplace-office-table_144627-40148.jpg?w=1380&t=st=1690596289~exp=1690596889~hmac=3fe80953b05c0b0091b2b7e41afb88c64c882419d046eae960a6f4cf97b9e968" 
                gradient="to top right, rgba(154,222,6,.33), rgba(25,32,72,.8)"></v-img>
            </template>
            <v-spacer></v-spacer>
            <v-toolbar-title class="display-2 white--text my-auto" >Tin tức</v-toolbar-title>
            <v-spacer></v-spacer>
        </v-toolbar>
        <v-row style="max-width:1200px" class="mx-auto mt-4">
            <v-col cols="12" md="8">
                <v-card min-height="350px" flat >
                    <v-card-title class="headline"><v-icon left x-large color="teal">mdi-history</v-icon> GẦN ĐÂY</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" md="6" v-for="content in contents" :key="content.code">
                                <v-card elevation="0" outlined>
                                    <v-img contain height="300px"
                                        :alt="`${content.title} avatar`"
                                        :src="content.avatar">
                                        <template v-slot:placeholder><img style="width:350px" src="https://media.istockphoto.com/id/1153672822/vector/contract-papers-document-folder-with-stamp-and-text-stack-of-agreements-document-with.jpg?s=612x612&w=0&k=20&c=kjNCXISJ69chBylPnz_pY_jDPlyus3qxB38soxW78J8=" alt=""></template>
                                    </v-img>
                                    <v-card-title class="font-weight-bold">
                                        {{content.title}}
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-subtitle><b>Ngày đăng:</b> {{ diffToHuman(content.createdAt) }}</v-card-subtitle>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <router-link  class="orange--text" :to="{ name: 'NewsDetail', params: { id: content.code }}">
                                            <span>Chi tiết</span>
                                        </router-link>
                                    </v-card-actions>
                                </v-card>

                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card min-height="" flat >
                    <v-list class="py-0 pb-4"  >
                        <v-card-title class="headline"><v-icon x-large color="pink" left>mdi-scatter-plot</v-icon> DANH MỤC</v-card-title>
                        <v-list-item v-for="category in categories" :key="category.code" color="red" :to="{name:'NewsCategory', params: {category:category.code}}" >
                            <v-list-item-content>
                                <v-list-item-title class="subtitle-1">
                                    <v-icon left color="secondary">mdi-chevron-right</v-icon>
                                    {{category.title}}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
const NEWS_URL = "contents/shows";
import MyBreadcrumb from '@/components/BreadCrumb';
import http from '@/components/services/http-common';
import moment from 'moment';
export default {
    name: "NewsIndex",
    props: ["breadcrumbs"],
    components:{
        MyBreadcrumb,
    },
    data(){
        return {
            contents: [],
            categories:[],
        }
    },
    methods:{
        diffToHuman(value){
            let date = moment(value, "YYYY-MM-DD").locale("vi");
            return moment(date).fromNow();
        },
        fetchData() {
            return http.get(NEWS_URL).then(res => {
                this.contents = res.data;
            }).catch(e => {
                console.log("xay ra loi khi load du lieu", e);
            })
        },
        fetchCategory(){
            return http.get("ContentCategories").then(res => {
                this.categories = res.data.results;
            });
        },
    },
    created(){
        this.fetchData();
        this.fetchCategory();
    },
    
    destroyed() {
    }
}
</script>

